import { Component } from '@angular/core';
import { ApiService } from '@services/api.service';

@Component({
	selector: 'app-base-with-layout',
	templateUrl: './base-with-layout.component.html',
	styleUrls: ['./base-with-layout.component.scss']
})
export class BaseWithLayoutComponent {
	isLogged: boolean;

	constructor(private apiService: ApiService) {
	}

	async ngOnInit() {
		this.isLogged = !!await this.apiService.getToken();
	}
}
