import {Pipe, PipeTransform} from '@angular/core';
import {environment} from '@env/environment';

/**
 * Get appName folder
 */
@Pipe({
	name: 'appfoldername'
})
export class AppfolderPipe implements PipeTransform {

	/**
	 * Replace value by appname
	 * @param value
	 */
	async transform(value: string) {
		return value
			.replace('/appfoldername/', '/' + environment.appName + '/')
			.replace('_appfoldername', '_' + environment.appName)
			.replace('%customname%', environment.customName)
			;
	}
}
