import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@env/environment';

console.log('START ', environment.file);
if (environment.production) {
	enableProdMode();
}
/**
 * Run the project
 */
platformBrowserDynamic().bootstrapModule(AppModule)
	.catch(err => console.error(err));
