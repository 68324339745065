import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {BaseComponent} from './base/base.component';
import {NavbarComponent} from './navbar/navbar.component';
import {ContentAnimateDirective} from '../../core/content-animate/content-animate.directive';
import {NgbDropdownModule, NgbCollapseModule} from '@ng-bootstrap/ng-bootstrap';
import {FeahterIconModule} from '../../core/feather-icon/feather-icon.module';
import {SharedModule} from '../../shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {BaseWithLayoutComponent} from './base-with-layout/base-with-layout.component';

@NgModule({
	declarations: [
		BaseComponent,
		BaseWithLayoutComponent,
		NavbarComponent,
		ContentAnimateDirective
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		NgbDropdownModule,
		NgbCollapseModule,
		FeahterIconModule,
		SharedModule,
		TranslateModule
	]
})
export class LayoutModule {
}
