import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [
	{
		label: 'home',
		icon: 'home',
		link: '/secured/dashboard'
	},
	/**
	 * {label: 'MES CARTES', icon: 'credit-card', link: '/auth/card'},
	 */
	{
		label: 'transactions',
		icon: 'map',
		link: '/secured/travel'
	},
	/*
	{
		label: 'MES PAIEMENTS',
		icon: 'file',
		link: '/secured/payment'
	},
	 */
	{
		label: 'alerts',
		icon: 'alert-triangle',
		link: '/secured/alert'
	}
];
