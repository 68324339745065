import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {LoginInterface} from '@interfaces/login.interface';
import {ApiService} from './api.service';
import {environment} from '@env/environment';
import {Subject} from 'rxjs';
import {TokenInterface} from '@interfaces/token.interface';
import {UserInterface} from '@interfaces/user.interface';
import {CreateAccountInterface} from '@interfaces/create-account.interface';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UpdateEmailComponent} from '../modals/update-email/update-email.component';
import {UpdatePasswordComponent} from '../modals/update-password/update-password.component';
import {RemoveAccountComponent} from '../modals/remove-account/remove-account.component';

/**
 * User service
 */
@Injectable({
	providedIn: 'root'
})
export class UserService {

	/**
	 * Constructor
	 * @param storage {StorageService} Storage service
	 * @param api {ApiService} Api service
	 * @param modalService
	 */
	constructor(private storage: StorageService,
				private api: ApiService,
				private modalService: NgbModal) {
	}

	/**
	 * Login event
	 */
	private loginAnnouncedSource = new Subject();
	/**
	 * Events for login
	 */
	public loginEvent$ = this.loginAnnouncedSource.asObservable();


	/**
	 * Check if user is logged
	 */
	async isLogged(withRole: string = ''): Promise<boolean> {
		try {
			const ret = await this.getUserInfo();
			if (withRole === '') {
				return true;
			}

			return ret.roles.indexOf(withRole) > -1;
		} catch (ex) {
			return false;
		}
	}

	/**
	 * Get my account info
	 */
	async myAccount() {
		return this.api.get(environment.baseApi + '/authapi/account/me');
	}

	/**
	 * Login user
	 * @param loginInfo {LoginInterface} Login informations
	 */
	async login(loginInfo: LoginInterface): Promise<any> {
		try {
			const path = await this.getLoginUrl();
			const ret: TokenInterface = await this.api.post(environment.baseApi + path, loginInfo);
			if (typeof ret.token !== 'undefined') {
				await this.api.storeToken(ret);
				this.loginAnnouncedSource.next();
				return true;
			}

			return false;
		} catch (e) {
			return false;
		}
	}

	/**
	 * Create an user
	 * @param account {CreateAccountInterface} Login informations
	 */
	async createAccount(account: CreateAccountInterface): Promise<boolean> {
		try {
			return await this.api.post(environment.baseApi + '/api/account/create', account);
		} catch (e) {
			return e.error.errors ? {errors: e.error.errors} : e.error.message
			return e.error.errors || e.error.message;
		}
	}

	async renewToken() {
		const token = await this.api.getToken();
		try {
			const newToken = await this.api.post(environment.baseApi + '/api/token/refresh', {
				refresh_token: token.refresh_token
			});

			await this.api.storeToken(newToken);

			return true;
		} catch (ex) {
			return false;
		}
	}


	/**
	 * Get user data
	 */
	async getUserInfo(): Promise<any> {
		const token: any = await this.api.getToken();
		if (token === null) {
			return null;
		}
		const uData: UserInterface = token.user;

		return uData;
	}


	/**
	 * Ask shop-card password
	 * @param email {string} Email to shop-card
	 */
	async resetLink(email): Promise<{ error: boolean, message?: string }> {
		return this.api.post(environment.baseApi + '/api/account/reset-link', {
			email
		});
	}

	/**
	 * Update password
	 * @param passwordData
	 * @param code
	 */
	async resetPassword(passwordData: string, code: string): Promise<any> {
		return this.api.post(environment.baseApi + '/api/account/reset/' + code, passwordData);
	}

	/**
	 * Logout user
	 */
	async logout() {
		await this.api.removeToken();
	}

	/**
	 * Confirm password
	 */
	async confirmWithPassword(email: string) {
		return new Promise(resolve => {
			const modalRef = this.modalService.open(UpdateEmailComponent, {
				centered: true,
				windowClass: 'small-modal'
			});

			modalRef.componentInstance.email = email;

			modalRef.result.then(async (result) => {
				resolve(true);
			}).catch((res) => {
				resolve(false);
			}).finally(async () => {
				resolve(false);

			});
		});
	}

	updateEmail(email: string, password: string) {
		return this.api.post(environment.baseApi + '/authapi/account/update-email', {
			email,
			password
		});
	}

	/**
	 * Confirm update password
	 */
	async confirmUpdatePassword(email: string) {
		return new Promise(resolve => {
			const modalRef = this.modalService.open(UpdatePasswordComponent, {
				centered: true,
				windowClass: 'small-modal'
			});

			modalRef.componentInstance.email = email;

			modalRef.result.then(async (result) => {
				resolve(true);
			}).catch((res) => {
				resolve(false);
			}).finally(async () => {
				resolve(false);

			});
		});
	}

	updatePassword(email: string, passwords: any) {
		return this.api.post(environment.baseApi + '/authapi/account/update-password', {
			email,
			current: passwords.current,
			password: passwords.password
		});
	}


	async updateNotifs(notifs: any) {
		return this.api.post(environment.baseApi + '/authapi/account/update-notifs', {
			notifs
		});
	}

	/**
	 * Get login url
	 */
	private async getLoginUrl(): Promise<string> {
		const ret = await this.api.get(environment.baseApi + '/api/config/login_url');
		return ret.config || '/api/login_check';
	}

	/**
	 * Confirm remove account
	 */
	async confirmRemoveAccount(email: string) {
		return new Promise(resolve => {
			const modalRef = this.modalService.open(RemoveAccountComponent, {
				centered: true,
				windowClass: 'small-modal'
			});

			modalRef.componentInstance.email = email;

			modalRef.result.then(async (result) => {
				resolve(true);
			}).catch((res) => {
				resolve(false);
			}).finally(async () => {
				resolve(false);
			});
		});
	}

	removeAccount(email: string, password: string) {
		return this.api.post(environment.baseApi + '/authapi/account/remove-account', {
			email,
			password
		});
	}

}
