import {Directive, AfterViewInit, ElementRef} from '@angular/core';
import feather from 'feather-icons';

@Directive({
	selector: '[appFeatherIcon]'
})
export class FeatherIconDirective implements AfterViewInit {

	constructor(private el: ElementRef) {
	}

	ngAfterViewInit() {
		// feather icon
		// console.log(this.el.nativeElement.getAttribute('data-feather'));
		feather.replace();
	}

}
