/**
 * Environment for prod
 */
export const environment = {
	file: 'cherbourg-dev',
	production: false,
	// baseApi: 'https://apicherbourgemv-preprod.kuba-dev.anura.fr',
	baseApi: 'https://apicherbourgemv-preprod.kuba-dev.anura.fr',
	appName: 'cherbourg',
	customName: 'Cap Cotentin',
	website: 'https://www.capcotentin.fr/',
	pdf: {
		name: 'Agence de Mobilité',
		address: [
			'40 boulevard Schuman',
			'50 100 CHERBOURG-EN-COTENTIN'
		],
	},
	lpLogo: 'https://palm-bus.qa.au.littlepay.com/assets/img/palm-bus.png',
	useMock: false,
	appConfig: {
		createAccount: true,
		path: '/auth/register',
		forgotPassword: '/auth/forget'
	},
	linksLegals: {
		privacy: 'https://www.capcotentin.fr/politique-confidentialite-cookies/',
		cgv: 'https://www.capcotentin.fr/cgv/'
	},
};
