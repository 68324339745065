import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DOCUMENT} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {StorageService} from '@services/storage.service';
import {ConfigService} from '@services/config.service';
import {environment} from '@env/environment';


declare var window;

/**
 * App component
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

	public currentLang: string = '';


	/**
	 * Constructor
	 * @param translateService {TranslateService}
	 * @param titleService {Title}
	 * @param storageService {StorageService}
	 * @param configService {ConfigService}
	 */
	constructor(private translateService: TranslateService,
				private titleService: Title,
				private storageService: StorageService,
				private configService: ConfigService) {

	}

	/**
	 * Angular on init
	 */
	async ngOnInit() {
		this.currentLang = this.storageService.getValue('app-language', 'fr-FR');

		const lang = this.storageService.getValue('app-language', 'fr-FR');
		this.translateService.setDefaultLang(lang);
		this.translateService.use(lang);
		this.storageService.setValue('app-language', lang);

		const title = await this.translateService.get('app.shared.apptitle_' + environment.appName).toPromise();
		this.titleService.setTitle(title);
		this.initDesign();
	}

	setLang(lang: string) {
		this.translateService.setDefaultLang(lang);
		this.translateService.use(lang);
		this.storageService.setValue('app-language', lang);
		this.currentLang = lang;
	}

	private initDesign() {
		const favicon = document.createElement('link');
		favicon.setAttribute('rel', 'icon');
		favicon.setAttribute('href', 'assets/' + environment.appName + '/images/favicon.ico');
		document.head.append(favicon);
		const style = document.createElement('style');
		style.setAttribute('type', 'text/css');
		style.innerText = `
		.body-login {
			background-image: url("assets/${environment.appName}/images/header-home.png");
		}
		@media (max-width: 420px) {
			.body-login {
				background-image: url("assets/${environment.appName}/images/header-home-2.png") !important;
				background-size: ${screen.width}px ${screen.width / (1440 / 454)}px;
				background-position-y: 125px;
			}
		}
		`;
		document.head.append(style);
		if (environment.appName === 'ginko') {
			const jsTag = document.createElement('script');
			jsTag.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-X5H399SFGH');
			jsTag.onload = () => {
				window.dataLayer = window.dataLayer || [];
				// tslint:disable-next-line:only-arrow-functions
				window.gtag = function () {
					window.dataLayer.push(arguments);
				}
				window.gtag('js', new Date());

				window.gtag('config', 'G-X5H399SFGH');
			};
			document.head.append(jsTag)
		}
	}
}
