import {Injectable} from '@angular/core';

/**
 * Storage service
 */
@Injectable({
	providedIn: 'root'
})
export class StorageService {
	/**
	 * Get a value from the storage
	 * @param key {string} Key to get
	 * @param defVal {any} Default value
	 */
	getValue(key: string, defVal = null) {
		const ret = localStorage.getItem(key);

		return ret === null ? defVal : JSON.parse(ret);
	}

	/**
	 * Delete a value from the storage
	 * @param key {string} Key to delete
	 */
	deleteValue(key: string) {
		const ret = localStorage.removeItem(key);
	}

	/**
	 * Set a value in the storage
	 * @param key {string} Key to set
	 * @param value {any} Value to set
	 */
	setValue(key: string, value: any) {
		localStorage.setItem(key, JSON.stringify(value));
	}
}
