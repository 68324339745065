import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import {UserService} from '@services/user.service';

/**
 * Token interceptor
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	/**
	 * Constructor
	 * @param apiService {ApiService} Api service
	 * @param router {Router} Angular router
	 * @param userService {UserService} User service
	 */
	constructor(private apiService: ApiService,
				private router: Router,
				private userService: UserService
	) {
	}

	/**
	 * Intercept request
	 * @param req
	 * @param next
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		if (req.url.indexOf('/authapi/') > -1 || req.url.indexOf('/guestapi/') > -1 || req.url.indexOf('/coownerapi/') > -1 || req.url.indexOf('/sharedapi/') > -1) {
			return from(this.apiService.getToken())
				.pipe(
					switchMap((token: any) => {
						if (token === null) {
							token = {
								token: 'unknow_token',
								refresh_token: 'unknow_refreshtoken'
							};
						}
						if (req.url.indexOf('addfiles') > -1) {
							req = req.clone({
								setHeaders: {
									Accept: 'application/json',
									Authorization: `Bearer ${token.token}`,
								},
							});
						} else {
							req = req.clone({
								setHeaders: {
									'Content-Type': 'application/json; charset=utf-8',
									Accept: 'application/json',
									Authorization: `Bearer ${token.token}`,
								},
							});
						}
						return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
							if (event instanceof HttpResponse) {
								return event;
							}
						}, async (err: any) => {
							if (req.url.indexOf('?nologin') > -1) {
								throw err;
							}
							if (err instanceof HttpErrorResponse) {
								if (err.status === 401) {
									try {
										const renewed = await this.userService.renewToken();
										if (!renewed) {
											return this.router.navigate(['/auth/login']);
										}
										return this.intercept(req, next);
									} catch (e) {
										this.router.navigate(['/auth/login']);
									}
								}
							}
						}));
						// return next.handle(req);
					})
				);
		} else {
			return next.handle(req);
		}
	}
}
