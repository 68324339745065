import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StorageService} from '@services/storage.service';
import {Router} from '@angular/router';

declare var window: any;

@Component({
	selector: 'app-footer-ginko',
	templateUrl: './footer-ginko.component.html',
	styleUrls: ['./footer-ginko.component.scss']
})
export class FooterGinkoComponent implements OnInit {
	@Input() currentLang: string;

	@Output() setLang: EventEmitter<string> = new EventEmitter<string>();

	showCookies: boolean = true;

	constructor(private storageService: StorageService,
				private router: Router) {
	}

	ngOnInit() {

	}


	urlGCS(): void {
		(window as any).open('https://www.ginko.voyage/cgv/', '_blank');
	}

	urlPrivacyPolicy(): void {
		(window as any).open('https://www.ginko.voyage/protection-des-donnees-personnelles/', '_blank');
	}

	urlLegalNotice(): void {
		this.router.navigate(['/secured/mention']);
	}

	urlGinko(): void {
		(window as any).open('https://www.ginko.voyage/', '_blank');
	}

	acceptCookies() {
		this.storageService.setValue('cookies', 'accept');
		this.showCookies = false;
	}

}
