import {ElementRef, Pipe, PipeTransform} from '@angular/core';
import {ConfigService} from '@services/config.service';
import {SharedModule} from '../shared.module';
import {environment} from '@env/environment';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
	name: 'customTranslate'
})
export class CustomtranslatePipe implements PipeTransform {

	constructor(private translate: TranslateService, private element: ElementRef) {
	}

	transform(value: string) {
		const namedKey = value + '_' + environment.appName;
		const valueTranslated = this.translate.instant(namedKey);

		if (valueTranslated === namedKey) {
			return this.translate.instant(value);
		} else {
			return valueTranslated;
		}
	}

}
