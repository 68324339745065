import {Injectable} from '@angular/core';

/**
 * Events provider
 */
@Injectable({
	providedIn: 'root'
})
export class EventsService {

	/**
	 * List of events
	 */
	public static events = {};

	/**
	 * Constructor
	 */
	constructor() {
	}

	/**
	 * Subscribe to an event
	 * @param evt {string} Event to subscribe
	 * @param cb {Function} Function to call when event is fired
	 */
	subscribe(evt, cb) {
		if (typeof EventsService.events[evt] === 'undefined') {
			EventsService.events[evt] = [];
		}

		EventsService.events[evt].push(cb);
	}

	/**
	 * UnSubscribe to an event
	 * @param evt {string} Event to subscribe
	 * @param cb {Function} Function to call when event is fired
	 */
	unsubscribe(evt, cb) {
		if (typeof EventsService.events[evt] === 'undefined') {
			return;
		}
		const newEvents = [];
		for (let i = 0; i < EventsService.events[evt].length; i++) {
			if (EventsService.events[evt][i] !== cb) {
				newEvents.push(EventsService.events[evt][i]);
			}
		}

		EventsService.events[evt] = newEvents;
	}

	/**
	 * Publish an event
	 * @param evt {string} Event to publish
	 * @param args {any} Arguments of the event
	 */
	publish(evt, args?) {
		if (typeof EventsService.events[evt] === 'undefined') {
			return;
		}
		for (let i = 0; i < EventsService.events[evt].length; i++) {
			try {
				EventsService.events[evt][i](args);
			} catch (e) {
				console.log('[ERROR]', e);
			}
		}
	}
}
