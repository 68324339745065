import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {LoginInterface} from '@interfaces/login.interface';
import {UserService} from '@services/user.service';
import {EventsService} from '@services/events.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {environment} from '@env/environment';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	/**
	 * Return url
	 */
	@Input() returnUrl: any;

	/**
	 * If is a modal
	 */
	public isModal: boolean = false;

	/**
	 * Login error
	 */
	public error: string = '';

	/**
	 * If data are loading
	 */
	public isLoading = false

	/**
	 * User login data
	 */
	public userLogin: LoginInterface = {
		username: '',
		password: ''
	}

	modalReference = null;

	/**
	 * If can create account
	 */
	public canCreateAccount: boolean = environment.appConfig.createAccount;


	constructor(private router: Router,
				private route: ActivatedRoute,
				private userService: UserService,
				private evt: EventsService,
				private modalService: NgbModal,
	) {
	}

	ngOnInit(): void {
		if (!this.isModal) {
			this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
		}
	}

	/**
	 * Login action
	 * @param e {Event}
	 */
	async onLoggedin(e) {
		e.preventDefault();
		this.error = '';
		this.isLoading = true;
		try {
			const isLogged = await this.userService.login(this.userLogin);
			if (!isLogged) {
				this.error = 'auth.login.login-error'
			} else {
				this.router.navigate([this.returnUrl]);
				this.evt.publish('app:login', true);
				this.modalReference.close();
			}
		} catch (e) {
			this.error = 'auth.login.login-error'
			this.isLoading = false;
		}
		this.isLoading = false;
	}

	createAccount(): void {
		if (environment.appConfig.path.indexOf('https://') > -1) {
			(window as any).open(environment.appConfig.path, '_blank');
		} else {
			this.router.navigate([environment.appConfig.path]);
		}
		this.modalService.dismissAll()
	}

	forgotPassword(): void {
		if (environment.appConfig.forgotPassword.indexOf('https://') > -1) {
			(window as any).open(environment.appConfig.forgotPassword, '_blank');
		} else {
			this.router.navigate([environment.appConfig.forgotPassword]);
		}
		this.modalService.dismissAll()
	}

	/**
	 * Open modal
	 * @param content {any} Modal content
	 */
	openBasicModal(content) {
		this.modalReference = this.modalService.open(content, {
			centered: true,
			windowClass: 'mobile-modal-login',
		});
	}

	urlGinko(): void {
		(window as any).open(
			environment.website, '_blank'
		);
	}
}
