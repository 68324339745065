import {Injectable} from '@angular/core';
import {CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import {Router} from '@angular/router';
import {ApiService} from '@services/api.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(private router: Router, private api: ApiService) {
	}

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const hasToken = await this.api.getToken()
		if (hasToken !== null) {
			// logged in so return true
			return true;
		}

		// not logged in so redirect to login page with the return url
		this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
		return false;
	}
}
