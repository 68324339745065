import {Component, HostBinding, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StorageService} from '@services/storage.service';
import {Router} from '@angular/router';
import {ConfigService} from '@services/config.service';
import {environment} from '@env/environment';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

	public currentLang: string = '';

	appName: string = '';

	@HostBinding('class') class = 'mt-auto';

	constructor(private translateService: TranslateService,
				private storageService: StorageService,
				private configService: ConfigService) {
	}

	async ngOnInit() {
		this.currentLang = this.storageService.getValue('app-language', 'fr-FR');
		this.appName = environment.appName;// this.storageService.getValue('app-name', 'unknow');
		if (this.appName === 'unknow') {
			this.appName = await this.configService.getConfig('appname');
			this.storageService.setValue('app-name', this.appName);
		}
	}

	setLang(lang: string) {
		this.translateService.setDefaultLang(lang);
		this.translateService.use(lang);
		this.storageService.setValue('app-language', lang);
		this.currentLang = lang;
	}
}
