import {Component, OnInit, Inject, Renderer2, HostListener, Input} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {Router, NavigationEnd} from '@angular/router';

import {MENU} from './menu';
import {MenuItem} from './menu.model';
import {UserService} from '@services/user.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UserInterface} from '@interfaces/user.interface';
import {environment} from '@env/environment';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

	menuItems = [];
	basicModalCloseResult: string = '';

	public user: UserInterface = null;

	public isCollapsed = true;

	@Input() withConnection: boolean = true;

	/**
	 * Fixed header menu on scroll
	 */
	@HostListener('window:scroll', ['$event']) getScrollHeight(event) {
		if (window.matchMedia('(min-width: 992px)').matches) {
			const header: HTMLElement = document.querySelector('.horizontal-menu') as HTMLElement;
			if (window.pageYOffset >= 60) {
				header.classList.add('fixed-on-scroll');
			} else {
				header.classList.remove('fixed-on-scroll');
			}
		}
	}

	constructor(private modalService: NgbModal,
				@Inject(DOCUMENT) private document: Document,
				private renderer: Renderer2,
				private router: Router,
				private userService: UserService
	) {
	}

	async ngOnInit() {
		this.user = await this.userService.getUserInfo();
		this.menuItems = MENU;

		/**
		 * closing the header menu after route change in tablet/mobile devices
		 */
		if (window.matchMedia('(max-width: 991px)').matches
		) {
			this.router.events.forEach((event) => {
				if (event instanceof NavigationEnd) {
					document.querySelector('.horizontal-menu .bottom-navbar').classList.remove('header-toggled');
				}
			});
		}
	}

	/**
	 * Returns true or false if given menu item has child or not
	 * @param item menuItem
	 */
	hasItems(item
				 :
				 MenuItem
	) {
		return item.subMenus !== undefined ? item.subMenus.length > 0 : false;
	}

	/**
	 * Logout
	 */
	onLogout(e) {
		e.preventDefault();
		this.userService.logout();
		localStorage.removeItem('isLoggedin');

		if (!localStorage.getItem('isLoggedin')) {
			this.router.navigate(['/auth/login']);
		}
	}

	/**
	 * Toggle header menu in tablet/mobile devices
	 */
	toggleHeaderMenu() {
		document.querySelector('.horizontal-menu .bottom-navbar').classList.toggle('header-toggled');
	}

	openBasicModal(content) {
		this.modalService.open(content, {windowClass: 'tutorial', centered: true}).result.then((result) => {
			this.basicModalCloseResult = result
		}).catch((res) => {
		});
	}

	urlGinko(): void {
		(window as any).open(environment.website, '_blank');
	}
}
