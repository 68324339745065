import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {StorageService} from './storage.service';
import {TokenInterface} from '@interfaces/token.interface';

/**
 * Api service
 */
@Injectable({
	providedIn: 'root'
})
export class ApiService {
	/**
	 * Api token for auth
	 */
	public static ApiToken: TokenInterface = null;


	/**
	 * Constructor
	 * @param http {HttpClient} Http client
	 * @param storage {StorageService} Storage service
	 */
	constructor(private http: HttpClient,
				private storage: StorageService) {
	}

	/**
	 * Get token data
	 */
	async getToken(): Promise<TokenInterface | null> {
		ApiService.ApiToken = await this.storage.getValue('user-token', null);
		return ApiService.ApiToken;
	}

	/**
	 * Get token data
	 */
	async storeToken(token: TokenInterface): Promise<void> {
		await this.storage.setValue('user-token', token);
	}

	/**
	 * Remove token
	 */
	async removeToken(): Promise<void> {
		await this.storage.deleteValue('user-token');
	}

	/**
	 * Make a get request
	 * @param url {string} Url
	 */
	async get(url: string): Promise<any> {
		return this.http.get(url).toPromise();
	}

	/**
	 * Make a delete request
	 * @param url {string} Url
	 */
	async delete(url: string): Promise<any> {
		return this.http.delete(url).toPromise();
	}

	/**
	 * Make a post request
	 * @param url {string} Url
	 * @param params {any} Data to send
	 */
	async post(url: string, params: any = {}): Promise<any> {
		return this.http.post(url, params).toPromise();
	}

	/**
	 * Make a put request
	 * @param url {string} Url
	 * @param params {any} Data to send
	 */
	async put(url: string, params: any): Promise<any> {
		return this.http.put(url, params).toPromise();
	}

	/**
	 * Upload file
	 * @param url {string} Url to upload
	 * @param file {File} File to upload
	 */
	uploadFile(url: string, file: File) {
		const formData = new FormData();
		formData.append('file', file);

		return this.http.post<any>(url, formData, {
			reportProgress: true,
			observe: 'events'
		});
	}
}
