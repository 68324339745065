import { Component, OnInit } from '@angular/core';
import { StorageService } from '@services/storage.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-lang-selector',
	templateUrl: './lang-selector.component.html',
	styleUrls: ['./lang-selector.component.scss']
})
export class LangSelectorComponent implements OnInit {

	public currentLang: string;

	constructor(
		private storageService: StorageService,
		private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		this.currentLang = this.storageService.getValue('app-language', 'fr-FR');
		const lang = this.storageService.getValue('app-language', 'fr-FR');
		this.translateService.setDefaultLang(lang);
		this.translateService.use(lang);
		this.storageService.setValue('app-language', lang);
	}

	setLang(lang: string) {
		this.translateService.setDefaultLang(lang);
		this.translateService.use(lang);
		this.storageService.setValue('app-language', lang);
		this.currentLang = lang;
	}
}
