import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';

import {LayoutModule} from './views/layout/layout.module';
import {AuthGuard} from './core/guard/auth.guard';

import {AppComponent} from './app.component';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {StorageService} from '@services/storage.service';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';

/**
 * Ngx Translate loader
 * @param http {HttpClient} Http client
 */
export function HttpLoaderFactory(http: HttpClient) {
	return new TranslateHttpLoader(http);
}

/**
 * Create the translate loader
 * @param http {HttpClient} Http client
 */
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * Get defined language
 * @param storage
 */
export function getDefinedLanguage(storage: StorageService): { key: string, icon: string, local: string } {
	return storage.getValue('app-language', {key: 'fr-FR', icon: 'fr', local: 'fr'})
}

/**
 * Main app module
 */
@NgModule({
	declarations: [
		AppComponent,
		ErrorPageComponent,
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		LayoutModule,
		HttpClientModule,
		TranslateModule.forRoot({
			defaultLanguage: getDefinedLanguage(new StorageService()).key,
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
	],
	providers: [
		AuthGuard,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AuthInterceptor,
			multi: true
		},

	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
