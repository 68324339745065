import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-modal-error',
	templateUrl: './error.component.html',
	styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

	@Input() title: string = 'error.title-error';

	@Input() content: string;

	/**
	 * Constructor
	 * @param activeModal {NgbActiveModal} Current modal
	 */
	constructor(public activeModal: NgbActiveModal) {
	}

}
