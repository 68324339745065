import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '@services/storage.service';
import {UserService} from '@services/user.service';

@Component({
	selector: 'app-update-email',
	templateUrl: './update-email.component.html',
	styleUrls: ['./update-email.component.scss']
})
export class UpdateEmailComponent implements OnInit {

	@Input() email: string = '';

	public error: string = '';
	public password: string = '';

	/**
	 * If data is loading
	 */
	public isLoading: boolean = false;
	/**
	 * If password has been updated
	 */
	public isUpdated: boolean = false;


	/**
	 * Constructor
	 * @param activeModal {NgbActiveModal} Current modal
	 * @param storageService
	 * @param userService
	 */
	constructor(public activeModal: NgbActiveModal,
				public storageService: StorageService,
				public userService: UserService) {
	}

	ngOnInit(): void {
	}

	/**
	 * Update emmail
	 */
	async validate() {
		this.error = '';
		this.isLoading = true;
		try {
			const ret = await this.userService.updateEmail(this.email, this.password);
			if (ret.error) {
				this.isLoading = false;
				return this.error = ret.message;
			}
			await this.userService.login({
				username: this.email,
				password: this.password
			});

			this.isLoading = false;
			this.isUpdated = true;
			setTimeout(() => {
				this.activeModal.close(true);
			}, 2000);
		} catch (e) {
			this.isLoading = false;
			this.error = 'unknow';
		}
	}

	/**
	 * Close the modal
	 */
	close(value: boolean = false) {
		this.activeModal.close(value);
	}

}
