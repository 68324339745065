import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {BaseComponent} from './views/layout/base/base.component';
import {AuthGuard} from './core/guard/auth.guard';
import {ErrorPageComponent} from './views/pages/error-page/error-page.component';
import {BaseWithLayoutComponent} from './views/layout/base-with-layout/base-with-layout.component';

/**
 * App route
 */
const routes: Routes = [
	{path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule)},
	{
		path: '',
		component: BaseComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'secured',
				loadChildren: () => import('./views/pages/secured/secured.module').then(m => m.SecuredModule)
			},
			{path: '', redirectTo: 'secured/dashboard', pathMatch: 'full'},
			// { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
		]
	},
	{
		path: 'error',
		component: BaseWithLayoutComponent,
		children: [
			{
				path: '',
				component: ErrorPageComponent,
				data: {
					type: 404,
					title: 'Page Not Found',
					desc: 'Oopps!! The page you were looking for doesn\'t exist.'
				}
			},
		]
	},
	{
		path: 'error/:type',
		component: ErrorPageComponent
	},
	{path: '**', redirectTo: 'error', pathMatch: 'full'}
];

/**
 * App routing
 */
@NgModule({
	imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
