import {Injectable} from '@angular/core';
import {ApiService} from '@services/api.service';
import {environment} from '@env/environment';
import {CardInterface} from '@interfaces/emv/card.interface';
import {EditCardComponent} from '../modals/edit-card/edit-card.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '@services/storage.service';

/**
 * Config service
 */
@Injectable({
	providedIn: 'root'
})

export class ConfigService {
	/**
	 * Constructor
	 * @param api {ApiService} Api Service
	 */
	constructor(private api: ApiService) {
	}

	/**
	 * Get a remote config
	 */
	async getConfig(config: string, defaultVal: any = null): Promise<any> {
		const ret = await this.api.get(environment.baseApi + '/api/config/' + config);
		return ret.config || defaultVal;
	}
}
