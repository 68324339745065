import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StorageService} from '@services/storage.service';
import {Router} from '@angular/router';

@Component({
	selector: 'app-footer-cherbourg',
	templateUrl: './footer-cherbourg.component.html',
	styleUrls: ['./footer-cherbourg.component.scss']
})
export class FooterCherbourgComponent implements OnInit {
	@Input() currentLang: string;

	@Output() setLang: EventEmitter<string> = new EventEmitter<string>();

	showCookies: boolean = true;

	constructor(private storageService: StorageService,
				private router: Router) {
	}

	ngOnInit(): void {
	}


	urlGCS(): void {
		(window as any).open('https://www.ginko.voyage/cgv/', '_blank');
	}

	urlPrivacyPolicy(): void {
		(window as any).open('https://www.capcotentin.fr/politique-confidentialite-cookies/', '_blank');
	}

	urlLegalNotice(): void {
		this.router.navigate(['/secured/mention']);
	}

	urlGinko(): void {
		(window as any).open('https://www.palmbus.fr/', '_blank');
	}

	acceptCookies() {
		this.storageService.setValue('cookies', 'accept');
		this.showCookies = false;
	}

}
