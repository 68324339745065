import {NgModule} from '@angular/core';
import 'moment/locale/fr';
import {CommonModule} from '@angular/common';


import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {FooterComponent} from './views/layout/footer/footer.component';
import {HeaderComponent} from './views/layout/header/header.component';
import {NgbAlertModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {AppfolderPipe} from './core/appfolder.pipe';
import {FooterDemoComponent} from './views/layout/footer/demo/footer-demo.component';
import {FooterGinkoComponent} from './views/layout/footer/ginko/footer-ginko.component';
import {CustomtranslatePipe} from './core/customtranslate.pipe';
import {ErrorComponent} from './modals/error/error.component';
import {UpdateEmailComponent} from './modals/update-email/update-email.component';
import {UpdatePasswordComponent} from './modals/update-password/update-password.component';
import {RemoveAccountComponent} from './modals/remove-account/remove-account.component';
import {FooterCannesComponent} from './views/layout/footer/cannes/footer-cannes.component';
import {FooterCherbourgComponent} from './views/layout/footer/cherbourg/footer-cherbourg.component';
import { LangSelectorComponent } from './core/lang-selector/lang-selector.component';

/**
 * Shared module
 */
@NgModule({
	declarations: [
		FooterComponent,
		HeaderComponent,
		AppfolderPipe,
		FooterComponent,
		FooterGinkoComponent,
		FooterDemoComponent,
		FooterCannesComponent,
		FooterCherbourgComponent,
		CustomtranslatePipe,
		ErrorComponent,
		UpdateEmailComponent,
		UpdatePasswordComponent,
		RemoveAccountComponent,
		LangSelectorComponent
	],
	imports: [
		CommonModule,
		TranslateModule,
		FormsModule,
		NgbDropdownModule,
		RouterModule,
		NgbAlertModule,
	],
	exports: [
		FooterComponent,
		HeaderComponent,
		AppfolderPipe,
		CustomtranslatePipe,
		ErrorComponent,
		UpdateEmailComponent,
		UpdatePasswordComponent,
		RemoveAccountComponent,
		LangSelectorComponent,
	],
	providers: [],
})
export class SharedModule {
	public static appFolderName: string = null;
}
