import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {StorageService} from '@services/storage.service';
import {UserService} from '@services/user.service';

@Component({
	selector: 'app-update-password',
	templateUrl: './update-password.component.html',
	styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {

	@Input() email: string = '';

	public error: string = '';

	public newPassword = {
		current: '',
		password: {first: '', second: ''},
	}

	/**
	 * If data is loading
	 */
	public isLoading: boolean = false;
	/**
	 * If password has been updated
	 */
	public isUpdated: boolean = false;


	/**
	 * List of errors
	 */
	public formErrors: any = {};

	/**
	 * Constructor
	 * @param activeModal {NgbActiveModal} Current modal
	 * @param storageService
	 * @param userService
	 */
	constructor(public activeModal: NgbActiveModal,
				public storageService: StorageService,
				public userService: UserService) {
	}

	ngOnInit(): void {
	}

	/**
	 * Update emmail
	 */
	async validate() {
		this.formErrors = {};
		this.isLoading = true;
		try {
			const ret = await this.userService.updatePassword(this.email, this.newPassword);
			if (ret.error) {
				this.isLoading = false;
				return this.formErrors = ret.errors;
			}

			await this.userService.login({
				username: this.email,
				password: this.newPassword.password.first
			});

			this.isLoading = false;
			this.isUpdated = true;
			setTimeout(() => {
				this.activeModal.close(true);
			}, 2000);
		} catch (e) {
			this.isLoading = false;
			this.error = 'unknow';
		}
		this.isLoading = false;
	}

	/**
	 * Close the modal
	 */
	close(value: boolean = false) {
		this.activeModal.close(value);
	}

}
